var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"cart"}},[_c('div',{staticClass:"search-mate"},[_c('SearchBar',{attrs:{"is-search-open":true},on:{"set-is-open":function($event){_vm.isSearchOpen = $event},"set-search-results":function($event){_vm.searchResults = $event},"set-term":function($event){_vm.term = $event},"set-loading":function($event){_vm.loading = $event}}}),_vm._v(" "),[(_vm.loading)?_c('div',{staticClass:"center"},[_c('div',{staticClass:"loader"})]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"close-icon",on:{"click":_vm.toggleSearchResults}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L17 17","stroke":"#171717","stroke-width":"2"}}),_vm._v(" "),_c('path',{attrs:{"d":"M17 1L1 17","stroke":"#171717","stroke-width":"2"}})])]):_vm._e()]],2),_vm._v(" "),(!_vm.loading)?_c('SfMegaMenu',{directives:[{name:"e2e",rawName:"v-e2e",value:('sidebar-cart'),expression:"'sidebar-cart'"}],staticClass:"sf-sidebar--right",attrs:{"visible":_vm.visible,"title":_vm.$t('Search results'),"position":"right"}},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.searchResults && _vm.searchResults.length > 0)?_c('div',{key:"results",staticClass:"search__wrapper-results"},[_c('SfMegaMenuColumn',{staticClass:"sf-mega-menu-column--pined-content-on-mobile search__results",attrs:{"title":_vm.$t('Product suggestions')},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
return [_c('SfMenuItem',{staticClass:"sf-mega-menu-column__header search__header",attrs:{"label":title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v(" ​ ")]},proxy:true}],null,true)})]}}],null,false,3476045800)},[_vm._v(" "),_c('SfScrollable',{staticClass:"results",attrs:{"show-text":"","hide-text":""}},[_c('div',{staticClass:"results-listing"},_vm._l((_vm.searchResults),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"image-tag":"nuxt-img","regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(
                    _vm.productGetters.getProductThumbnailImage(product)
                  ),"nuxt-img-config":{
                  fit: 'cover',
                },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(
                    ("/p/" + (_vm.productGetters.getProductSku(
                      product
                    )) + (_vm.productGetters.getSlug(
                      product,
                      product.categories[0]
                    )))
                  ),"wishlist-icon":false},scopedSlots:_vm._u([{key:"reviews",fn:function(){return [_c('SearchResultLabel',{attrs:{"product":product}})]},proxy:true}],null,true)})}),1)]),_vm._v(" "),_c('div',{staticClass:"results--mobile smartphone-only"},_vm._l((_vm.searchResults),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"image-tag":"nuxt-img","regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCardHorizontal.width,"image-height":_vm.imageSizes.productCardHorizontal.height,"image":_vm.getMagentoImage(
                  _vm.productGetters.getProductThumbnailImage(product)
                ),"nuxt-img-config":{
                fit: 'cover',
              },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(
                  ("/p/" + (_vm.productGetters.getProductSku(
                    product
                  )) + (_vm.productGetters.getSlug(product, product.categories[0])))
                ),"wishlist-icon":false},scopedSlots:_vm._u([{key:"reviews",fn:function(){return [_c('SearchResultLabel',{attrs:{"product":product}})]},proxy:true}],null,true)})}),1)],1),_vm._v(" "),_c('div',{staticClass:"action-buttons smartphone-only"},[_c('SfButton',{staticClass:"action-buttons__button color-light",on:{"click":_vm.toggleSearchResults}},[_vm._v("\n            "+_vm._s(_vm.$t("Cancel"))+"\n          ")])],1)],1):_c('div',{key:"no-results",staticClass:"before-results"},[_c('SvgImage',{staticClass:"before-results__picture",attrs:{"icon":"error_image","label":_vm.$t('Error'),"width":"412","height":"412"}}),_vm._v(" "),_c('p',{staticClass:"before-results__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t("You haven’t searched for items yet"))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"before-results__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t("Let’s start now – we’ll help you"))+"\n        ")]),_vm._v(" "),_c('SfButton',{staticClass:"before-results__button color-secondary smartphone-only",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n          "+_vm._s(_vm.$t("Go back"))+"\n        ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }