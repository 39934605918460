import {
  readonly,
  ref,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { EntityUrl } from '~/modules/GraphQL/types';
import type { UseUrlResolverErrors, UseUrlResolverInterface } from '~/composables';

/**
 * Allows searching the resolver for current
 * route path (URL).
 *
 * See the {@link UseUrlResolverInterface} for a list of methods and values available in this composable.
 */
export function useUrlResolver(): UseUrlResolverInterface {
  const route = useRoute();
  const { error: nuxtError, app } = useContext();
  const context = app.$vsf;
  const { path } = route.value;
  const loading = ref(false);
  const error = ref<UseUrlResolverErrors>({
    search: null,
  });

  const search = async (): Promise<EntityUrl | {}> => {
    loading.value = true;
    let results: EntityUrl = {};

    try {
      let p = path.split('/c/')
      // const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '');
      let path1 = '/'+p[1]
      Logger.debug('[Magento] Find information based on URL', { path1 });
      const { data } = await context.$magento.api.urlResolver(path1);
      console.log(data, 'if done');
      results = data.urlResolver;

      if (!results) nuxtError({ statusCode: 404 });

      Logger.debug('[Result]:', { results });
    } catch (err) {
      error.value.search = err;

      Logger.error('useUrlResolver/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    path,
    search,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './UseUrlResolver';
export default useUrlResolver;
