import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _037dd10c = () => interopDefault(import('../pages/Action.vue' /* webpackChunkName: "pages/Action" */))
const _296f82b5 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _ceb15912 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6ff9147a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _66b2c7ce = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78257916 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _61b344bd = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _8665c8ac = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _22f1ee8a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1ba897f8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _674e806c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _f8429318 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _416bb178 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _29811390 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _21d68638 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _530036c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _1d0bb296 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _526b59bb = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _79a49865 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _d80066ac = () => interopDefault(import('../pages/footer/Privacy.vue' /* webpackChunkName: "" */))
const _3a4524b8 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _3a4e8636 = () => interopDefault(import('../pages/footer/Returns.vue' /* webpackChunkName: "" */))
const _780831a1 = () => interopDefault(import('../pages/SearchedProducts.vue' /* webpackChunkName: "pages/SearchedProducts" */))
const _52f30e92 = () => interopDefault(import('../pages/footer/FooterShipping.vue' /* webpackChunkName: "" */))
const _396d73ce = () => interopDefault(import('../pages/TakePayment.vue' /* webpackChunkName: "" */))
const _08c9fb41 = () => interopDefault(import('../pages/footer/TermsConditions.vue' /* webpackChunkName: "" */))
const _26498809 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _cda98b00 = () => interopDefault(import('../pages/footer/ContactUs.vue' /* webpackChunkName: "pages/footer/ContactUs" */))
const _4c045ea7 = () => interopDefault(import('../modules/checkout/pages/Action.vue' /* webpackChunkName: "" */))
const _e6a80caa = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _2f3b291c = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Action",
    component: _037dd10c,
    name: "Action___default"
  }, {
    path: "/cart",
    component: _296f82b5,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _ceb15912,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _6ff9147a,
      name: "billing___default"
    }, {
      path: "payment",
      component: _66b2c7ce,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78257916,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _61b344bd,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _8665c8ac,
      name: "user-account___default"
    }]
  }, {
    path: "/contact",
    component: _296f82b5,
    name: "contact___default"
  }, {
    path: "/customer",
    component: _22f1ee8a,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _1ba897f8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _674e806c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _f8429318,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _416bb178,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _29811390,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _21d68638,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _530036c4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d0bb296,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _526b59bb,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/forgot-password",
    component: _296f82b5,
    name: "forgot-password___default"
  }, {
    path: "/Home",
    component: _296f82b5,
    name: "Home___default"
  }, {
    path: "/login",
    component: _296f82b5,
    name: "login___default"
  }, {
    path: "/Page",
    component: _79a49865,
    name: "Page___default"
  }, {
    path: "/privacy",
    component: _d80066ac,
    name: "privacy___default"
  }, {
    path: "/reset-password",
    component: _3a4524b8,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/ResetPassword",
    component: _3a4524b8,
    name: "ResetPassword___default"
  }, {
    path: "/returns",
    component: _3a4e8636,
    name: "returns___default"
  }, {
    path: "/SearchedProducts",
    component: _780831a1,
    name: "SearchedProducts___default"
  }, {
    path: "/shipping",
    component: _52f30e92,
    name: "shipping___default"
  }, {
    path: "/takePayment",
    component: _396d73ce,
    name: "takePayment___default"
  }, {
    path: "/TakePayment",
    component: _396d73ce,
    name: "TakePayment___default"
  }, {
    path: "/terms-conditions",
    component: _08c9fb41,
    name: "terms-conditions___default"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _26498809,
    name: "Checkout-ExternalCheckoutThankYou___default"
  }, {
    path: "/footer/ContactUs",
    component: _cda98b00,
    name: "footer-ContactUs___default"
  }, {
    path: "/footer/FooterShipping",
    component: _52f30e92,
    name: "footer-FooterShipping___default"
  }, {
    path: "/footer/Privacy",
    component: _d80066ac,
    name: "footer-Privacy___default"
  }, {
    path: "/footer/Returns",
    component: _3a4e8636,
    name: "footer-Returns___default"
  }, {
    path: "/footer/TermsConditions",
    component: _08c9fb41,
    name: "footer-TermsConditions___default"
  }, {
    path: "/paypal/action/return",
    component: _4c045ea7,
    name: "paypal___default"
  }, {
    path: "/searched-products/:slug",
    component: _780831a1,
    name: "searchedProducts___default"
  }, {
    path: "/p/:id/:slug",
    component: _e6a80caa,
    name: "product___default"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _2f3b291c,
    name: "category___default"
  }, {
    path: "/",
    component: _296f82b5,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _79a49865,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
